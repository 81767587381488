* {

  }


a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}


  @font-face {
    font-family: "finder-icons";
    src: url("./assets/fonts/finder-icons.ttf") format("truetype"), url("./assets/fonts/finder-icons.woff") format("woff"), url("./assets/fonts/finder-icons.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  [class^=fi-], [class*=" fi-"] {
    display: inline-block;
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "finder-icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    vertical-align: middle;
    line-height: 1;
  }


  .simplebar-placeholder {
    display: none!important;
  }
  .thumbnailImg {
    height: 80px;
    width:150px;
    border-color: grey;
    border: 1px solid rgb(214, 214, 214);

  }

  .display-7 {
    font-size: 2.3rem;
    line-height: 36px;
    letter-spacing: -1px;
  }

  .posImg {
    position: absolute;
    cursor: pointer;

    justify-content: center;

  }
 
  select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
  }

  /* register background  */


  /* For IE10 */
  select::-ms-expand {
    display: none;
  }
  .highlighFh {
    position: relative;
  }
  
  .highlighFh::before {
    /* Highlight color */
    background-color: #ffd99c;
  
    content: "";
    position: absolute;
    width: calc(100% + 4px);
    height: 60%;
    left: -2px;
    bottom: 0;
    z-index: -1;
    transform: rotate(-2deg);
  }
  .background {
    background-image:linear-gradient(to bottom, rgba(230, 230, 230, 0.5) 0%,rgba(170, 170, 170, 0.403) 100%),url("./assets/home.png");
    background-color: #fff; /* Used if the image is unavailable */
    height: 500px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dotback {
    background-color: #ffffff;
    opacity: 1;
    border-radius: 40px;
    background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #ffffff 29px ), repeating-linear-gradient( #ffd97655, #ffd976 );
  }
  .imgHero {
    height: 740px;
    left: 0;
  }
  .fitting-image {
    width: 450px;
    height: 470px;
    object-fit:cover;
    border-radius: 10px;

  }
  .mobSmall {
    min-width: 210px;
  }
  .thumbnail{
    height: 80px!important;
    width:80px!important;
    border-radius: 40px!important;
    border-color: grey;
    border: 1px solid rgb(214, 214, 214);

  }
  .text-primary {
    color: #fd7e14!important;
  }
  .text-primaryB {
    color: #F66700!important;
  }
  .bold {
    font-weight: bold!important;
  }
  .cursor {
    z-index: 1000px!important;
    cursor: pointer!important;
  }
  .cardflo {
    position: abs;
  }

  .BigAlert {
    background-color: #ff9d00;
    width: 100%;
    display: flex;
    height: 70px;
    justify-content: center;
    align-items: center;
  }
  .BigAlert a {
    font-size: 20px;
  }
  .imgActivo {
    height: auto!important;
    width: 100%!important;
    border-radius: 20px;
    max-height: 800px;
  }
  .containerCartel {
    height: 48px;
    background-color: #F66700;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding-left: 9px;
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .containerCarteltext {
    padding-top: 8px!important;;
    font-weight: bolder!important;;
    font-size: 24px!important;;
    color:#000!important;;
  }

  .noMobilPadTop {
    margin-top: 60px!important;
  }

  .pl-2 {
    margin-left: 20px!important;
  }


  @media only screen and (max-width: 600px) {
    .imgHero {
    }
    .cardVerificacion {
      width: 100%!important;
      margin: 0 !important;
      padding: 0 !important;

    }

    .pl-2 {
      margin-left: 0px!important;
      
    }
    .btn-md {
      width: 200px;
    }
  
    .mob {
      padding-top: 20px!important;
    }
    .noMobile {
      display: none;

    }
    .text-decoration-none {
      text-decoration: none!important;
    }
    .width-40 {
      width: 100%!important;
    }
    .width-60 {
      width: 100%!important;
    
    }
    .noMobilPadTop {
      margin-top: 0px!important;
      top:0
    }

    .nav-item {
      text-decoration: none!important;
    }
    .nav-link {
      text-decoration: none!important;
    }
    .pr-2 {
      margin-left: 50px!important;
    }
 
    .fixed-height {
      /* margin-bottom: 20px; */
    }
    .btn-outline-secondary {
      min-width: 100px
    }
    .priceMobile {
      display: block;
    }
    .cardFixed {
      height: 300px!important;
      max-height: 310px!important;
    }
    .cardFixedParti {
      height: 280px!important;
      max-height: 280px!important;
    }
    .text-small-mobile {
      font-size: 13px;
    }
    .desktopNo {
      display: block!important;
    }
    .fixed-heightCard {
      height: 250px!important;
    }
    .imgActivo {
      height: auto!important;
      width: 100%!important;
      padding-bottom:30px!important;
      border-radius: 20px;
    }
  }

  .desktopNo {
    display: none;
  }
  
  @media only screen and (max-width: 1200px) { 
    .priceMobile {
      display: none!important;
    }
    .semi {
      display: none!important;

    }
    .semiSi {
      display: block!important;
    }
    .float-right {
      position: absolute;
      right: 10px;
      border-radius: 0px;
      margin-top:0px!important;
    }
  }
  .semiSi {
    display: none;
  }
  .mt-2 {
    margin-top: 30px!important;
  }
  .backgroundS {
    /* background-image: url("./assets/bg.jpbackgroundg")!important; */
    background:none!important;
    background-color: #cccccc; /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }


.card {
  /* position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(0, 34, 28, 0.1);
  border-radius: 1.2125rem;
  padding: 20px; */
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
  padding-top: 0px!important;
}

.card-title {
  margin-bottom: 0.25rem;
}

.card-subtitle {
  margin-top: -0.125rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}
ion-icon {
  cursor: pointer;
}
ion-icon:hover {
  color: #fd7e14!important;
}

.fixed-heightCard {
  height: 350px;
}

.card-header {
  padding: 1.25rem 1.25rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
  padding-bottom:0px ;
}

.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

.card-footer {
  padding: 1.25rem 1.25rem;
  background-color: transparent;
  border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);
}

.card-footer:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1.25rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}
.float-right {
  position: absolute;
  right: 10px;
  border-radius: 0px;
  margin-top:20px
}
.card-img,
.card-img-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.nav-item {
  cursor: pointer;
}
.link {
  cursor: pointer;

}
.mt-10 {
  margin-top: 80px;
}
.badge {
  cursor: pointer;
}

.width-40 {
  width: 40%;
}
.width-60 {
  width: 60%;

}
.minWidthbtn {
  min-width: 90px
}
.width-100 {
  width: 100%!important;
}
.pld-10 {
  margin-left: 15px;
}


/* Reviews truspilot  */

.fixed-height {
  height: 30px!important;
}

.cardFixed {
  min-height: 180px!important;
  height: 100px!important;

}
.cardFixedParti {
  min-height: 220px!important;
  height: 280px!important;

}
.minWidth {
  min-width: 240px;
}
.table-success {
  border-radius: 10px;
  border:3px;
  border: solid black;
}
.badge-primary-light {
  --bs-badge-padding-x: 0.45em!important;
    --bs-badge-padding-y: 0.3em;
    --bs-badge-font-size: 80%;
    --bs-badge-font-weight: 600;
    --bs-badge-color: #fff;
    --bs-badge-border-radius: 0.2rem;
    border-radius: var(--bs-badge-border-radius);
    color: var(--bs-badge-color);
    display: inline-block;
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    line-height: 1;
    padding: var(0.3em) var(0.45em);
    text-align: center;
}
.bg-idealista {
  background-color: #DEF171!important;
}

.thumbnailCard {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.thumbnailCard img{
  max-width: 100%;
}
.fixWidth {
  width: 94%;
}